import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import styles from "@styles/views/SellContract/sell-resume.module.scss";

import PaneHeader from "@components/PaneHeader";
import { WarningPanel } from "@components/InfoBox";
import FormattedNumberInput from "@components/FormattedNumberInput";
import FormFormattedNumberInput from "@components/Form/FormattedNumberInput/FormFormattedNumberInput";

const SellResume = ({ areTotalAmountsValid, setAreTotalAmountsValid }) => {
	const { watch } = useFormContext();

	const pvp = watch("sellInfo.pvp");

	const ownershipChangeAmount = watch("sellInfo.ownershipChangeAmount");

	const wantsTransport = watch("sellInfo.transport.wants", false)?.value;
	const transportAmount = watch("sellInfo.transport.amount");

	const wantsWarrantyPremium = watch("sellInfo.warranty.premiumWants", false)?.value;
	const warrantyPremiumAmount = watch("sellInfo.warranty.premiumAmount");

	const wantsWarrantyExtra = watch("sellInfo.warranty.extraWants", false)?.value;
	const warrantyExtraAmount = watch("sellInfo.warranty.extraAmount");

	const wantsInsurance = watch("sellInfo.insurance.wants", false)?.value;
	const insuranceAmount = watch("sellInfo.insurance.amount");

	const financingAmount = watch("payAmounts.financingAmount");
	const dealershipTPVAmount = watch("payAmounts.dealershipTPVAmount");
	const virtualTPVAmount = watch("payAmounts.virtualTPVAmount");
	const cashAmount = watch("payAmounts.cashAmount");
	const transferenceAmount = watch("payAmounts.transferenceAmount");
	const carPayAmount = watch("payAmounts.carPayAmount");

	const [totalAmount, setTotalAmount] = useState(0);
	const [payTotalAmount, setPayTotalAmount] = useState(0);

	useEffect(() => {
		let updatedAmount = pvp + ownershipChangeAmount;

		if (wantsTransport) updatedAmount += transportAmount;
		if (wantsInsurance) updatedAmount += insuranceAmount;
		if (wantsWarrantyPremium) updatedAmount += warrantyPremiumAmount;
		if (wantsWarrantyPremium && wantsWarrantyExtra) updatedAmount += warrantyExtraAmount;

		setTotalAmount(updatedAmount);
	}, [
		pvp,
		ownershipChangeAmount,
		wantsTransport,
		transportAmount,
		wantsWarrantyPremium,
		warrantyPremiumAmount,
		wantsWarrantyExtra,
		warrantyExtraAmount,
		wantsInsurance,
		insuranceAmount
	]);

	useEffect(() => {
		const newPayAmount =
			financingAmount +
			dealershipTPVAmount +
			virtualTPVAmount +
			cashAmount +
			transferenceAmount +
			carPayAmount;

		setPayTotalAmount(newPayAmount);
	}, [
		financingAmount,
		dealershipTPVAmount,
		virtualTPVAmount,
		cashAmount,
		transferenceAmount,
		carPayAmount
	]);

	useEffect(() => {
		setAreTotalAmountsValid(totalAmount === payTotalAmount);
	}, [setAreTotalAmountsValid, totalAmount, payTotalAmount]);

	return (
		<div>
			<PaneHeader title="Resumen de la venta" />

			<div className={styles.container}>
				<div>
					<PaneHeader
						title="Productos contratados"
						color="secondary"
					/>

					<table className={styles.sellResume}>
						<thead>
							<tr>
								<th>Producto contratado</th>
								<th>Importe</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>PVP venta</td>
								<td>
									<FormattedNumberInput
										value={pvp}
										displayType="text"
									/>
								</td>
							</tr>
							<tr>
								<td>Cambio de titularidad</td>
								<td>
									<FormattedNumberInput
										value={ownershipChangeAmount}
										displayType="text"
									/>
								</td>
							</tr>

							{wantsTransport && (
								<tr>
									<td>Transporte</td>
									<td>
										<FormattedNumberInput
											value={transportAmount}
											displayType="text"
										/>
									</td>
								</tr>
							)}

							{wantsWarrantyPremium && (
								<tr>
									<td>Garantía Premium</td>
									<td>
										<FormattedNumberInput
											value={warrantyPremiumAmount}
											displayType="text"
										/>
									</td>
								</tr>
							)}
							{wantsWarrantyPremium && wantsWarrantyExtra && (
								<tr>
									<td>Extra Garantía</td>
									<td>
										<FormattedNumberInput
											value={warrantyExtraAmount}
											displayType="text"
										/>
									</td>
								</tr>
							)}
							{wantsInsurance && (
								<tr>
									<td>Seguro Auto</td>
									<td>
										<FormattedNumberInput
											value={insuranceAmount}
											displayType="text"
										/>
									</td>
								</tr>
							)}
							<tr>
								<td>Total</td>
								<td>
									<FormattedNumberInput
										value={totalAmount}
										displayType="text"
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div>
					<PaneHeader
						title="Formas de pago"
						color="secondary"
					/>

					<div className={styles.payMethods}>
						<div className={styles.payAmounts}>
							<FormFormattedNumberInput
								name="payAmounts.financingAmount"
								label="Financiado"
							/>
							<FormFormattedNumberInput
								name="payAmounts.dealershipTPVAmount"
								label="TPV Tienda"
							/>
							<FormFormattedNumberInput
								name="payAmounts.virtualTPVAmount"
								label="TPV Virtual"
							/>
							<FormFormattedNumberInput
								name="payAmounts.cashAmount"
								label="Efectivo"
							/>
							<FormFormattedNumberInput
								name="payAmounts.transferenceAmount"
								label="Transferencia"
							/>
							<FormFormattedNumberInput
								name="payAmounts.carPayAmount"
								label="Coche pago"
							/>
						</div>

						<div className={styles.payTotalContainer}>
							<label>Total</label>
							<FormattedNumberInput
								value={payTotalAmount}
								onChange={setPayTotalAmount}
								disabled
							/>
							{areTotalAmountsValid && (
								<span className={styles.validatedAmount}>Formas de pago validadas</span>
							)}
						</div>
					</div>

					{!areTotalAmountsValid && (
						<WarningPanel text="La suma de los importes introducidos en formas de pago, debe coincidir con el importe total de los productos contratados." />
					)}
				</div>
			</div>
		</div>
	);
};

export default SellResume;
