import React, { useState, useEffect, useCallback } from "react";
import { Table } from "react-bootstrap";
import PaginationFooter from "@components/PaginationFooter";
import { MdEdit, MdGroupAdd } from "react-icons/md";
import styles from "./index.module.scss";
import { useHistory } from "react-router-dom";
import RolType from "@constants/roles";

const TableStock = ({
  rol,
  loadVehiclePage,
  changePagination,
  launchModal,
  launchPromoState,
  showNumb,
  paginationMin,
  cars,
  totalCars,
  changeOrder,
  order,
  loadVehicleEdition,
  setState
}) => {
  const [orderDir, setOrderDir] = useState(null);
  const [orderValue, setOrderValue] = useState(null);
  const [showIcons, setShowIcons] = useState(false);
  const history = useHistory();
 
  const setOrder = (v) => {
    let d = "desc";
    if (v === orderValue) {
      d = orderDir === "asc" ? "desc" : "asc";
    }
    changeOrder(v, d);
    setOrderValue(v);
    setOrderDir(d);
  };

  const renderArrow = (v) => {
    if (orderValue === v) {
      return orderDir === "asc" ? "🔼" : "🔽";
    }
  };

  useEffect(() => {
    if (!order.value || !order.dir) {
      setOrderDir(null);
      setOrderValue(null);
    }
  }, [order]);

  const renderCarStateCircle = (car) => {
    if (car.color_verde === "verde_activo") {
      return (
        <>
          <div className={`circulo ${car.color_verde}`} />
          <span>Disponible</span>
        </>
      );
    }
    if (car.color_amarillo === "amarillo_activo") {
    return (
        <>
          <div className={`circulo ${car.color_amarillo}`} />
          <span>Reservado</span>
        </>
      );
    }
    if (car.color_rojo === "rojo_activo") {
      return (
        <>
          <div className={`circulo ${car.color_rojo}`} />
          <span>Vendido</span>
        </>
      );
    }

    return <></>;
  };

  const getBtnVisibility = useCallback(() => {
		if (rol.includes(1)) {
			setShowIcons(true);
			return;
		} else if (
			rol.includes(15) ||
			rol.includes(16) ||
			rol.includes(17) ||
			rol.includes(18) ||
			rol.includes(2) ||
			rol.includes(3) ||
			rol.includes(4) ||
			rol.includes(5) ||
			rol.includes(8) ||
			rol.includes(13) ||
			rol.includes(14) ||
			rol.includes(19) ||
			rol.includes(22) ||
			rol.includes(23) ||
			rol.includes(24) ||
			rol.includes(25) ||
			rol.includes(26) ||
			rol.includes(27) ||
			rol.includes(28) ||
			rol.includes(29) ||
			rol.includes(35)
		) {
			history.push("/main/stock");
			setShowIcons(false);
			return;
		} else if (rol.includes(6) || rol.includes(7) || rol.includes(37) || rol.includes(RolType.TERRITORIAL)) {
			setShowIcons(true);
			return;
		}
	}, [history, rol]);

	useEffect(() => {
		getBtnVisibility();
	}, [rol, getBtnVisibility]);

  return (
    <div className={styles.tableContainer}>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>Imagen</th>
            <th>Local</th>
            <th>Vehículo</th>
            <th>Matrícula</th>
            <th onClick={() => setOrder("v.pvp_contado")} className="pointer">
              Contado {renderArrow("v.pvp_contado")}
            </th>
            <th
              onClick={() => setOrder("v.pvp_financiado")}
              className="pointer"
            >
              Financiado {renderArrow("v.pvp_financiado")}
            </th>
            <th onClick={() => setOrder("v.combustible")} className="pointer">
              Comb. {renderArrow("v.combustible")}
            </th>
            <th onClick={() => setOrder("v.anio")} className="pointer">
              Año {renderArrow("v.anio")}
            </th>
            <th onClick={() => setOrder("v.kilometros")} className="pointer">
              Km. {renderArrow("v.kilometros")}
            </th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {cars.map((car, idx) => {
            return (
              <tr
                key={"vehicle" + idx}
                style={
                  idx % 2
                    ? { background: "#ffffff" }
                    : { background: "rgba(242,242,242,0.5)" }
                }
              >
                <td>
                  <span
                    className="pointer"
                    onClick={() => {
                      loadVehiclePage(car.vehicle_id);
                    }}
                  >
                    <div
                      style={{
                        width: "20px",
                        height: "60px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }}
                    >
                      <div className={`circulo ${car.color_verde}`} />
                      <div className={`circulo ${car.color_amarillo}`} />
                      <div className={`circulo ${car.color_rojo}`} />
                    </div>
                  </span>
                </td>
                <td>
                  <span
                    className="pointer"
                    onClick={() => loadVehiclePage(car.vehicle_id)}
                  >
                    <img
                      alt={"carimg-" + idx}
                      src={car.url}
                      height="80"
                      width="120"
                      className={styles.carImg}
                    />
                    <div className={styles.car_state_container}>
                      {renderCarStateCircle(car)}
                    </div>
                  </span>
                </td>
                <td data-label="Local">
                  <span
                    className="pointer"
                    onClick={() => loadVehiclePage(car.vehicle_id)}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    {car.nombre}
                  </span>
                </td>
                <td data-label="Vehículo">
                  <span
                    className="pointer"
                    onClick={() => loadVehiclePage(car.vehicle_id)}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    {car.vehiculo}
                  </span>
                </td>
                <td data-label="Matrícula">
                  <span
                    className="pointer"
                    onClick={() => loadVehiclePage(car.vehicle_id)}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    {car.matricula}
                  </span>
                </td>
                <td data-label="PVP al contado">
                  <span
                    className="pointer"
                    onClick={() => loadVehiclePage(car.vehicle_id)}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    {Math.trunc(car.pvp_contado)
                      .toLocaleString("en")
                      .replace(/,/, ".")}{" "}
                    €
                  </span>
                </td>
                <td data-label="PVP financiado">
                  <span
                    className="pointer"
                    onClick={() => loadVehiclePage(car.vehicle_id)}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    {Math.trunc(car.pvp_financiado)
                      .toLocaleString("en")
                      .replace(/,/, ".")}{" "}
                    €
                  </span>
                </td>
                <td data-label="Combustible">
                  <span
                    className="pointer"
                    onClick={() => loadVehiclePage(car.vehicle_id)}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    {car.combustible}
                  </span>
                </td>
                <td data-label="Año">
                  <span
                    className="pointer"
                    onClick={() => loadVehiclePage(car.vehicle_id)}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    {car.aniomes}
                  </span>
                </td>
                <td data-label="Km.">
                  <span
                    className="pointer"
                    onClick={() => loadVehiclePage(car.vehicle_id)}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    {car.kilometros.toLocaleString("en").replace(/,/, ".")}
                  </span>
                </td>
                <td>
                  <div className={styles.icons}>
                    <button
                      title="Crear Lead"
                      onClick={() => {
                        setState(prev => ({
                          ...prev,
                          checkLead: {
                            ...prev.checkLead,
                            vehicleId: car.vehicle_id
                          }
                        }))
                        launchModal("modal_check", car.vehicle_id, true)
                      }}
                    >
                      <MdGroupAdd
                        color="#007bff"
                        size={36}
                        className="px-1 mx-1"
                        title="Crear Lead"
                        data-toggle="tooltip"
                        data-placement="top"
                        role="button"
                      />
                      <span>Crear Lead</span>
                    </button>
                    {rol.indexOf(1) !== -1 || rol.indexOf(7) !== -1 ? (
                      <div className={styles.icon}>
                        <div
                          id={"outlet-" + car.vehicle_id}
                          onClick={() =>
                            launchPromoState(
                              car.outlet === 1 ? 0 : 1,
                              car.vehicle_id,
                              idx
                            )
                          }
                          className={`circulo pointer ${
                            car.outlet === 1 ? "verde_activo" : "verde_inactivo"
                          } d-none d-lg-block`}
                        ></div>
                      </div>
                    ) : (
                      <span> </span>
                    )}
										{car.vehicle_id_flexicar && showIcons && (
											<button
												title="Editar Vehículo"
												onClick={() => loadVehicleEdition(car.vehicle_id_flexicar)}
											>
												<MdEdit
													size={36}
													color="#007bff"
													className="px-1 mx-1"
													data-toggle="tooltip"
													data-placement="top"
													title="Editar Vehículo"
													role="button"
												/>
												<span>Editar Vehículo</span>
											</button>
										)}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <PaginationFooter
        paginationMin={paginationMin}
        total={totalCars}
        showNumb={showNumb}
        elements={cars}
        changePagination={changePagination}
      />
    </div>
  );
};

export default TableStock;
