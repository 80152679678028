import React, { useContext, useEffect, useState } from "react";
import { Dropdown, Nav, Accordion } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import "./style.css";
import { UserContext } from "@contexts/UserContext";
import {
  MdDirectionsCarFilled,
  MdEvent,
  MdContacts,
  MdCarRepair,
} from "react-icons/md";
import { AiFillFile, AiFillCaretDown, AiOutlineMenu } from "react-icons/ai";

import permissions from "@constants/permissions";
import Locale from "@constants/Locale";
import { useTranslation } from "react-i18next";

const CustomNavbar = () => {
  const { permission, rol, email, logOut } = useContext(UserContext);
  const { i18n, t } = useTranslation("menu");
  const [mercedes, setMercedes] = useState(false);
  const [showMenuMobile, setShowMenuMobile] = useState(false);

  const portugueseAccessProtection = i18n.language === Locale.ES

  useEffect(() => {
    if (rol.map((elm) => elm === 35).includes(true)) {
      setMercedes(true);
    }
  }, [rol]);


  const checkPermission = (itemPermissionId) => {
    let access = false;
    itemPermissionId.forEach((id) => {
      if (permission) {
        let i = 0;
        do {
          i++;
          if (permission.includes(id)) {
            access = true;
          }
        } while (!access && i < itemPermissionId.length);
      }
    });
    return access;
  };

  

  return (
    <div>
      {/* desktop menu */}
      <nav className={mercedes ? "navbar mercedes" : "navbar"}>
        <div>
          <NavLink to="/main/admin">
            <img
              src="/images/logo.png"
              className="d-inline-block align-top mx-3"
              alt="flexicar-logo"
            />
          </NavLink>
        </div>
        {rol && (
          <div className="navbar_menu maxContent">
            <ul className="menu">



              {checkPermission(permissions.contratos.id) && (
                <li className="menu_li">
                  <div className="menu_li_container">
                    <AiFillFile color="#fe9800" size={25} />
                    <div className="hasDropdown maxContent">
                      <span>{t("contracts.key")}</span>
                      <AiFillCaretDown color="#fe9800" size={14} />
                      <ul className="dropdown__container">
                        <div className="dropdown__container__list">
                          <div className="dropdown__container__listItem">
                            {checkPermission(
                              permissions.contratos.crearContrato
                            ) && (
                              <div>
                                <NavLink
                                  className="custom__link container"
                                  to="/main/nuevo_contrato"
                                >
                                  <h6>{t("contracts.create")}</h6>
                                </NavLink>
                              </div>
                            )}

                            {checkPermission(
                              permissions.contratos.documentacion
                            ) && (
                              <div>
                                <NavLink
                                  className="custom__link container"
                                  to="/main/postventa_contratos"
                                >
                                  <h6>{t("contracts.docs")}</h6>
                                </NavLink>
                              </div>
                            )}
                          </div>
                        </div>
                      </ul>
                    </div>
                  </div>
                </li>
              )}

              {checkPermission(permissions.leads.id) && (
                <li className="menu_li">
                  <div className="menu_li_container">
                    <MdContacts color="#fe9800" size={25} />
                    <div className="hasDropdown maxContent">
                      <span>{t("leads.key")}</span>
                      <AiFillCaretDown color="#fe9800" size={14} />
                      <ul className="dropdown__container">
                        <div className="dropdown__container__list">
                          <div className="dropdown__container__listItem">
                            {checkPermission(permissions.leads.todosLeads) && (
                              <div>
                                <NavLink
                                  className="custom__link container"
                                  to="/main/listaleads?section=todos"
                                >
                                  {t("leads.purchase.key")}
                                </NavLink>
                              </div>
                            )}

                            {checkPermission(
                              permissions.leads.leadsPropios
                            ) && (
                              <div>
                                <NavLink
                                  className="custom__link container"
                                  to="/main/listaleads?section=propios"
                                >
                                  {t("leads.purchase.ownPurchase")}
                                </NavLink>
                              </div>
                            )}

                            {checkPermission(
                              permissions.leads.leadsPendientes
                            ) && (
                              <div>
                                <NavLink
                                  className="custom__link container"
                                  to="/main/listaleads?section=pendientes"
                                >
                                  {t("leads.purchase.pending")}
                                </NavLink>
                              </div>
                            )}

                            {checkPermission(
                              permissions.leads.leadsPropiosPendientes
                            ) && (
                              <div>
                                <NavLink
                                  className="custom__link container"
                                  to="/main/listaleads?section=propios_pendientes"
                                >
                                  {t("leads.purchase.ownPending")}
                                </NavLink>
                              </div>
                            )}

                            {checkPermission(
                              permissions.leads.leadsAtendidos
                            ) && (
                              <div>
                                <NavLink
                                  className="custom__link container"
                                  to="/main/listaleads?section=listaleedsalgunagestion"
                                >
                                  {t("leads.purchase.attended")}
                                </NavLink>
                              </div>
                            )}
                          
                            {checkPermission(
                              permissions.leads.leadsPendientesTasacion
                            ) && (
                              <div>
                                <NavLink
                                  className="custom__link container"
                                  to="/main/listaleads?section=pendientestasacion"
                                >
                                  {t("leads.purchase.appraisalPending")}
                                </NavLink>
                                </div>
                              )}
                            <Dropdown.Divider />
                            {checkPermission(permissions.leads.leadsVenta) && (
                              <div>
                                <NavLink
                                  className="custom__link container"
                                  to="/main/listaleadsventa?section=todos"
                                >
                                  {t("leads.sales.key")}
                                </NavLink>
                              </div>
                            )}

                            {checkPermission(
                              permissions.leads.leadsPropiosVenta
                            ) && (
                              <div>
                                <NavLink
                                  className="custom__link container"
                                  to="/main/listaleadsventa?section=propios"
                                >
                                  {t("leads.sales.ownSales")}
                                </NavLink>
                              </div>
                            )}

                            {checkPermission(permissions.leads.destacados) && (
                              <div>
                                <NavLink
                                  className="custom__link container"
                                  to="/main/listaleadsventa?section=destacados"
                                >
                                  {t("leads.sales.outstanding")}
                                </NavLink>
                              </div>
                            )}

                            {checkPermission(
                              permissions.leads.leadsVentaTienda
                            ) && (
                              <div>
                                <NavLink
                                  className="custom__link container"
                                  to="/main/listaleadsventa?section=mitienda"
                                >
                                  {t("leads.sales.myStore")}
                                </NavLink>
                              </div>
                            )}
                          </div>
                        </div>
                      </ul>
                    </div>
                  </div>
                </li>
              )}

              {checkPermission(permissions.citas.id) && (
                <li className="menu_li">
                  <div className="menu_li_container">
                    <MdEvent color="#fe9800" size={25} />
                    <div className="hasDropdown maxContent">
                      <span>{t("appointments.key")}</span>
                      <AiFillCaretDown color="#fe9800" size={14} />
                      <ul className="dropdown__container">
                        <div className="dropdown__container__list">
                          <div className="dropdown__container__listItem">
                            {checkPermission(permissions.citas.citasCompra) && (
                              <div>
                                <Nav.Link
                                  className="custom__link container"
                                  href="/main/citas-compras"
                                >
                                  <h6>{t("appointments.purchase")}</h6>
                                </Nav.Link>
                              </div>
                            )}

                            {checkPermission(permissions.citas.citasVenta) && (
                              <div>
                                <Nav.Link
                                  className="custom__link container"
                                  href="/main/citas-ventas"
                                >
                                  <h6>{t("appointments.sales")}</h6>
                                </Nav.Link>
                              </div>
                            )}
                            {checkPermission(permissions.citas.citasPostVenta) && (
                              <div>
                                <Nav.Link
                                  className="custom__link container"
                                  href="/main/citas-post-venta"
                                >
                                  <h6>{t("appointments.afterSales")}</h6>
                                </Nav.Link>
                              </div>
                            )}
                            {portugueseAccessProtection && checkPermission(permissions.citas.reservas) && (
                              <div>
                                <NavLink
                                  className="custom__link container"
                                  to="/main/reservas"
                                >
                                  <h6>{t("appointments.myBookings")}</h6>
                                </NavLink>
                              </div>
                            )}

                            {portugueseAccessProtection && checkPermission(permissions.citas.entregas) && (
                              <div>
                                <NavLink
                                  className="custom__link container"
                                  to="/main/entregas"
                                >
                                  <h6>{t("appointments.myDeliveries")}</h6>
                                </NavLink>
                              </div>
                            )}
                          </div>
                        </div>
                      </ul>
                    </div>
                  </div>
                </li>
              )}

              {checkPermission(permissions.stock.id) && (
                <li className="menu_li">
                  <NavLink
                    className="menu_li_link custom__link"
                    to="/main/stock"
                  >
                    <MdDirectionsCarFilled color="#fe9800" size={25} />
                    <h6
                      style={{ margin: "0", top: "2px", fontSize: "18px" }}
                      className="custom__position__text"
                    >
                      {t("stock")}
                    </h6>
                  </NavLink>
                </li>
              )}

              {checkPermission(permissions.validacionVo.id) && (
                <li className="menu_li">
                  <NavLink
                    className="menu_li_link custom__link maxContent"
                    to="/main/validacion-vehiculo"
                  >
                    <MdCarRepair color="#fe9800" size={25} />
                    <h6
                      style={{ margin: "0", top: "2px", fontSize: "18px" }}
                      className="custom__position__text"
                    >
                      {t("validation")}
                    </h6>
                  </NavLink>
                </li>
              )}

              <li className="menu_li">
                <div className="menu_li_container">
                  <div className="hasDropdown maxContent">
                    <span>{`@${email.split("@")[0]}`}</span>
                    <AiFillCaretDown color="#fe9800" size={14} />
                    <ul className="dropdown__container last_dropdown">
                      <div className="dropdown__container__list">
                        <div className="dropdown__container__listItem">
                          <div>
                            <NavLink
                              className="custom__link container"
                              to="/main/video-tutorial"
                            >
                              <h6>{t("help")}</h6>
                            </NavLink>
                          </div>

                          <Dropdown.Divider />
                          <div>
                            <Nav.Link
                              className="custom__link container"
                              href="/main/login"
                              onClick={logOut}
                            >
                              <h6>{t("logout")}</h6>
                            </Nav.Link>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        )}
      </nav>

      {/* mobile menu */}
      <nav className={mercedes ? "mobile-navbar mercedes" : "mobile-navbar"}>
        <div className="mobile-navbar-container">
          <NavLink to="/main/admin">
            <img
              src="/images/logo.png"
              className="d-inline-block align-top mx-3 w-75"
              alt="flexicar-logo"
            />
          </NavLink>

          <div
            className="hamburger_container"
            onClick={() => setShowMenuMobile(!showMenuMobile)}
          >
            <AiOutlineMenu color="#00000080" size={30} />
          </div>
        </div>
        {rol && (
          <div
            className={
              showMenuMobile
                ? "mobile-menu-container show_navbar"
                : "mobile-menu-container"
            }
          >
            <Accordion>
              {checkPermission(permissions.contratos.id) && (
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <div className="menu_li_container">
                      <AiFillFile color="#fe9800" size={25} />
                      <div className="hasDropdown">
                        <span>{t("contracts.key")}</span>
                        <AiFillCaretDown color="#fe9800" size={14} />
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="accordion_body_container">
                      {checkPermission(permissions.contratos.crearContrato) && portugueseAccessProtection && (
                        <div>
                          <NavLink
                            className="custom__link container"
                            to="/main/nuevo_contrato"
                            onClick={() => setShowMenuMobile(false)}
                          >
                            <span>{t("contracts.create")}</span>
                          </NavLink>
                        </div>
                      )}

                      {checkPermission(permissions.contratos.documentacion) && (
                        <div>
                          <NavLink
                            className="custom__link container"
                            to="/main/postventa_contratos"
                            onClick={() => setShowMenuMobile(false)}
                          >
                            <span>{t("contracts.docs")}</span>
                          </NavLink>
                        </div>
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}

              {checkPermission(permissions.leads.id) && (
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <div className="menu_li_container">
                      <MdContacts color="#fe9800" size={25} />
                      <div className="hasDropdown">
                        <span>{t("leads.key")}</span>
                        <AiFillCaretDown color="#fe9800" size={14} />
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="accordion_body_container">
                      {checkPermission(permissions.leads.todosLeads) && (
                        <div>
                          <NavLink
                            className="custom__link container"
                            to="/main/listaleads?section=todos"
                            onClick={() => setShowMenuMobile(false)}
                          >
                            {t("leads.purchase.key")}
                          </NavLink>
                        </div>
                      )}

                      {checkPermission(permissions.leads.leadsPropios) && (
                        <div>
                          <NavLink
                            className="custom__link container"
                            to="/main/listaleads?section=propios"
                            onClick={() => setShowMenuMobile(false)}
                          >
                            {t("leads.purchase.ownPurchase")}
                          </NavLink>
                        </div>
                      )}

                      {checkPermission(permissions.leads.leadsPendientes) && (
                        <div>
                          <NavLink
                            className="custom__link container"
                            to="/main/listaleads?section=pendientes"
                            onClick={() => setShowMenuMobile(false)}
                          >
                            {t("leads.purchase.pending")}
                          </NavLink>
                        </div>
                      )}

                      {checkPermission(
                        permissions.leads.leadsPropiosPendientes
                      ) && (
                        <div>
                          <NavLink
                            className="custom__link container"
                            to="/main/listaleads?section=propios_pendientes"
                            onClick={() => setShowMenuMobile(false)}
                          >
                            {t("leads.purchase.ownPending")}
                          </NavLink>
                        </div>
                      )}

                      {checkPermission(permissions.leads.leadsAtendidos) && (
                        <div>
                          <NavLink
                            className="custom__link container"
                            to="/main/listaleads?section=listaleedsalgunagestion"
                            onClick={() => setShowMenuMobile(false)}
                          >
                            {t("leads.purchase.attended")}
                          </NavLink>
                        </div>
                      )}

                      <Dropdown.Divider />
                      {checkPermission(permissions.leads.leadsVenta) && (
                        <div>
                          <NavLink
                            className="custom__link container"
                            to="/main/listaleadsventa?section=todos"
                            onClick={() => setShowMenuMobile(false)}
                          >
                            {t("leads.sales.key")}
                          </NavLink>
                        </div>
                      )}

                      {checkPermission(permissions.leads.leadsPropiosVenta) && (
                        <div>
                          <NavLink
                            className="custom__link container"
                            to="/main/listaleadsventa?section=propios"
                            onClick={() => setShowMenuMobile(false)}
                          >
                            {t("leads.sales.ownSales")}
                          </NavLink>
                        </div>
                      )}

                      {checkPermission(permissions.leads.destacados) && (
                        <div>
                          <NavLink
                            className="custom__link container"
                            to="/main/listaleadsventa?section=destacados"
                            onClick={() => setShowMenuMobile(false)}
                          >
                            {t("leads.sales.outstanding")}
                          </NavLink>
                        </div>
                      )}

                      {checkPermission(permissions.leads.leadsVentaTienda) && (
                        <div>
                          <NavLink
                            className="custom__link container"
                            to="/main/listaleadsventa?section=mitienda"
                            onClick={() => setShowMenuMobile(false)}
                          >
                            {t("leads.sales.myStore")}
                          </NavLink>
                        </div>
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}

              {checkPermission(permissions.citas.id) && (
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <div className="menu_li_container">
                      <MdEvent color="#fe9800" size={25} />
                      <div className="hasDropdown">
                        <span>{t("appointments.key")}</span>
                        <AiFillCaretDown color="#fe9800" size={14} />
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="accordion_body_container">
                      {checkPermission(permissions.citas.citasCompra) && (
                        <div>
                          <Nav.Link
                            className="custom__link container"
                            href="/main/citas-compras"
                          >
                            <span>{t("appointments.purchase")}</span>
                          </Nav.Link>
                        </div>
                      )}

                      {checkPermission(permissions.citas.citasVenta) && (
                        <div>
                          <Nav.Link
                            className="custom__link container"
                            href="/main/citas-ventas"
                          >
                            <span>{t("appointments.sales")}</span>
                          </Nav.Link>
                        </div>
                      )}
                       {checkPermission(permissions.citas.citasPostVenta) && (
                        <div>
                          <Nav.Link
                            className="custom__link container"
                            href="/main/citas-post-venta"
                          >
                            <span>{t("appointments.afterSales")}</span>
                          </Nav.Link>
                        </div>
                      )}
                      {portugueseAccessProtection && checkPermission(permissions.citas.reservas) && (
                        <div>
                          <NavLink
                            className="custom__link container"
                            to="/main/reservas"
                            onClick={() => setShowMenuMobile(false)}
                          >
                            <span>{t("appointments.myBookings")}</span>
                          </NavLink>
                        </div>
                      )}

                      {portugueseAccessProtection && checkPermission(permissions.citas.entregas) && (
                        <div>
                          <NavLink
                            className="custom__link container"
                            to="/main/entregas"
                            onClick={() => setShowMenuMobile(false)}
                          >
                            <span>{t("appointments.myDeliveries")}</span>
                          </NavLink>
                        </div>
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}

              {checkPermission(permissions.stock.id) && (
                <div className="menu_li_container single_item">
                  <NavLink
                    className="menu_li_link maxContent"
                    to="/main/stock"
                    onClick={() => setShowMenuMobile(false)}
                  >
                    <MdDirectionsCarFilled color="#fe9800" size={25} />
                    <span
                      style={{ margin: "0", top: "2px", fontSize: "24px" }}
                      className="custom__position__text"
                    >
                      {t("stock")}
                    </span>
                  </NavLink>
                </div>
              )}

              {portugueseAccessProtection && checkPermission(permissions.validacionVo.id) && (
                <div className="menu_li_container single_item">
                  <NavLink
                    className="menu_li_link maxContent"
                    to="/main/validacion-vehiculo"
                    onClick={() => setShowMenuMobile(false)}
                  >
                    <MdCarRepair color="#fe9800" size={25} />
                    <span
                      style={{ margin: "0", top: "2px", fontSize: "24px" }}
                      className="custom__position__text"
                    >
                      {t("validation")}
                    </span>
                  </NavLink>
                </div>
              )}

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <div className="menu_li_container">
                    <div className="hasDropdown">
                      <span>{`@${email.split("@")[0]}`}</span>
                      <AiFillCaretDown color="#fe9800" size={14} />
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="accordion_body_container">
                    <div>
                      <NavLink
                        className="custom__link container"
                        to="/main/video-tutorial"
                        onClick={() => setShowMenuMobile(false)}
                      >
                        <span>{t("help")}</span>
                      </NavLink>
                    </div>
                    <Dropdown.Divider />
                    <div>
                      <NavLink
                        className="custom__link container"
                        to="/main/login"
                        onClick={logOut}
                      >
                        <span>{t("logout")}</span>
                      </NavLink>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        )}
      </nav>

    </div>
  );
};

export default CustomNavbar;
